<template>
  <div>
    <p>I did...</p>
    <p><a id="photography" title="bikeshare" href="https://ausstellung.hfg-gmuend.de/w-2021/projekte/bike-share" @mouseover="chBg(1);" @mouseleave="chBg(0);"
        target="_blank">…bikeshare</a></p>
    <p><a id="videography" title="discovery robot" href="https://ausstellung.hfg-gmuend.de/s-2020/projekte/erkundungsroboter" @mouseover="chBg(2);" @mouseleave="chBg(0);"
        target="_blank">…discovery robot</a></p>
    <p>
      <router-link id="back" title="◄ go back" to="/">◄ back</router-link>
    </p>
  </div>
</template>

<script>
  export default {
    name: 'Projects',
    data() {
      return {
      };
    },
    methods: {
      chBg(index) {
        console.log(index);
        switch (index) {
          case 1:
            this.$store.state.bgImg = 'bikeshare.png';
            console.log('test');
            break;
          case 2:
            this.$store.state.bgImg = 'robot.jpg';
            break;
          case 3:
            this.$store.state.bgImg = '';
            break;
          default:
            this.$store.state.bgImg = '';
            break;
        }
      }
    }
  }
</script>

<style>

</style>